import loadable from "@loadable/component"
import Box from "@material-ui/core/Box"
import React, { useLayoutEffect, useState } from "react"
import { flatten, hasCommonElements } from "../../../helpers"
import { OverviewDataFragment } from "../../../__generated__/graphql-gatsby"

const Employee = loadable(() => import("./employee"))
const Grid = loadable(() => import("./grid"))
const List = loadable(() => import("./list"))
const Logos = loadable(() => import("./logos"))

export type OverviewArray = Array<Maybe<OverviewDataFragment>>

interface IProps {
  data: OverviewArray
  sessionKey: string
  format?: Maybe<string>
  sort?: Maybe<string>
  tags: Maybe<string[]>
}

type Shuffle = (array: OverviewArray) => OverviewArray
type Sort = (array: OverviewArray, sorting: string) => OverviewArray

const shuffle: Shuffle = array => {
  const copy = [...array]
  let currentIndex = copy.length

  while (currentIndex != 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[copy[currentIndex], copy[randomIndex]] = [
      copy[randomIndex],
      copy[currentIndex],
    ]
  }

  return copy
}

const sort: Sort = (array, sorting) =>
  array.sort((a, b) => {
    const ta = String(a?.title)
    const tb = String(b?.title)

    if (sorting === "za") {
      return tb < ta ? -1 : ta > tb ? 1 : 0
    } else {
      return ta < tb ? -1 : tb > ta ? 1 : 0
    }
  })

type RenderSwitch = (
  param: Maybe<string>,
  data: OverviewArray,
  sessionKey: string
) => React.ReactNode
const renderSwitch: RenderSwitch = (param, data, sessionKey) => {
  switch (param) {
    case "list":
      return <List data={data} sessionKey={sessionKey} />
    case "grid":
    case "text-grid":
      return (
        <Grid
          data={data}
          sessionKey={sessionKey}
          text={param === "text-grid"}
        />
      )
    case "logos":
    case "logos-text":
    case "employee-sm":
      return (
        <Logos
          data={data}
          withText={param === "employee-sm" || param === "logos-text"}
          round={param === "employee-sm"}
        />
      )
    case "employee-md":
    case "employee-lg":
      return <Employee data={data} large={param === "employee-lg"} />
    default:
      return null
  }
}

interface ShuffledProps {
  format: Maybe<string>
  data: OverviewArray
  sessionKey: string
}
const Shuffled: React.FC<ShuffledProps> = ({ format, data, sessionKey }) => {
  const [overviewData, setData] = useState<false | OverviewArray>(false)

  useLayoutEffect(() => {
    setData(shuffle(data))
  }, [data])

  if (!overviewData) return null

  return <Box marginY={4}>{renderSwitch(format, overviewData, sessionKey)}</Box>
}

const Overview: React.FC<IProps> = ({
  data: dataProp,
  format,
  sort: sortProp,
  sessionKey,
  tags,
}) => {
  let data: OverviewArray
  if (tags) {
    data = dataProp.filter(item => {
      switch (item?.__typename) {
        case "WpActiviteit":
        case "WpErvaring":
        case "WpVrijwilliger":
          return hasCommonElements(item.tags?.nodes?.map(flatten), tags)
        default:
          return true
      }
    })
  } else {
    data = dataProp
  }

  if (sortProp && sortProp !== "new") {
    if (sortProp === "shuffle") {
      return <Shuffled format={format} data={data} sessionKey={sessionKey} />
    } else {
      data = sort(data, sortProp)
    }
  }
  return <Box marginY={4}>{renderSwitch(format, data, sessionKey)}</Box>
}

export default Overview
